<template>
  <v-container fluid>
    <v-data-table
      item-key="token"
      single-expand
      show-expand
      :headers="apiTokenTableHeaders"
      :items="apiTokens"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="apiTokenTableHeaders.length" class="expanded-content">
          <v-text-field prepend-inner-icon="search" label="Search" v-model="item.search"></v-text-field>
          <span v-for="(domain,index) in getItemDomains(item)" :key="domain+index+'_'">
            <v-chip label class="mx-2 mt-1 mb-2">{{domain}}</v-chip>
          </span>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top dark>
          <template v-slot:activator="{ on }">
            <v-btn
              elevation="0"
              @click
              slot="activator"
              fab
              small
              dark
              v-on="on"
              color="primary"
              :class="$vuetify.breakpoint.width > 960 ? 'ml-0 mr-3 xs-small' : 'ml-3 xs-small'"
            >
              <v-icon small>fas fa-cog</v-icon>
            </v-btn>
          </template>
          <span>Settings</span>
        </v-tooltip>
      </template>
      <template v-slot:item.active="{ item }">
        <v-switch inset v-model="item.active"></v-switch>
      </template>
    </v-data-table>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          @click="newApiTokenDialog=true"
          fab
          fixed
          dark
          bottom
          right
          color="primary"
          v-on="on"
        >
          <v-icon small>fas fa-plus</v-icon>
        </v-btn>
      </template>
      <span>Create new</span>
    </v-tooltip>
    <v-dialog
      v-model="newApiTokenDialog"
      scrollable
      persistent
      max-width="750px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>Create New API Token</v-card-title>
        <v-card-text>
          <v-text-field label="Name" v-model="newApiToken.name"></v-text-field>
          <v-autocomplete
            v-model="newApiToken.application"
            :items="applications"
            label="Choose application"
            cache-items
            item-text="name"
            item-value="id"
          ></v-autocomplete>
          <v-select :items="access_levels" v-model="newApiToken.access_level" label="Access Level"></v-select>
          <v-select :items="client_types" v-model="newApiToken.client_type" label="Client Type"></v-select>
          <v-combobox
            v-model="newApiToken.domains"
            :items="newApiToken.domains"
            hide-selected
            multiple
            deletable-chips
            chips
          ></v-combobox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darkGrey" text class="text-capitalize" @click="resetNewApiToken">Cancel</v-btn>
          <v-btn @click="generateNewApiToken" color="primary" class="text-capitalize">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { AuthAPI } from '@/clients/auth/auth.api'

export default {
  mounted() {
    this.getApiTokens()
    this.getAllApplications()
  },
  data() {
    return {
      apiTokens: [],
      apiTokenTableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'App Name',
          align: 'start',
          sortable: true,
          value: 'application_name'
        },
        {
          text: 'Client Type',
          align: 'start',
          sortable: true,
          value: 'client_type'
        },
        {
          text: 'Access Level',
          align: 'start',
          sortable: true,
          value: 'access_level'
        },
        {
          text: 'Token',
          align: 'start',
          sortable: true,
          value: 'token'
        },
        {
          text: 'Active',
          align: 'start',
          sortable: false,
          value: 'active'
        },
        {
          text: 'Actions',
          align: 'start',
          sortable: false,
          value: 'actions'
        }
      ],
      newApiTokenDialog: false,
      client_types: ['web', 'ios', 'android', 'mobile', 'all'],
      access_levels: ['unoapp', 'partner', 'client', 'other'],
      newApiToken: {
        application: '',
        name: '',
        access_level: '',
        client_type: '',
        domains: []
      },
      applications: []
    }
  },
  methods: {
    async getApiTokens() {
      let response
      try {
        response = await AuthAPI.getAllApiTokens()
      } catch (error) {
        return console.log(error)
      }
      this.apiTokens = response.data.payload
      this.apiTokens.forEach(i => this.$set(i, 'search', ''))
    },
    getItemDomains(item) {
      if (item.search !== '') {
        let lowerCaseSearch = item.search.toLowerCase()
        return item.domains.filter(i => i.toLowerCase().indexOf(lowerCaseSearch) > -1)
      }
      return item.domains
    },
    resetNewApiToken() {
      this.newApiTokenDialog = false
      this.newApiToken = {
        application: '',
        name: '',
        access_level: '',
        client_type: '',
        domains: []
      }
    },
    async generateNewApiToken() {
      try {
        await AuthAPI.generateApiTokens(this.newApiToken.application, this.newApiToken)
      } catch (error) {
        return console.log(error)
      }
      this.getApiTokens()
      this.resetNewApiToken()
    },
    async getAllApplications() {
      let response
      try {
        response = await AuthAPI.getAllApplications()
      } catch (error) {
        return console.log(error)
      }
      this.applications = response.data.payload
    }
  }
}
</script>

<style lang="scss" scoped>
.expanded-content {
}
.v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0 4px 8px -5px rgba(50, 50, 50, 0) !important,
    inset 0 -4px 8px -5px rgba(50, 50, 5, 0) !important;
}
</style>
